app.controller("ActionController", [
"$scope",
"microflowFac",
function($scope, microflowFac) {
	$scope.fn_4a3aab04_501d_3eea_a5e9_d4fe9a506a9a = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_a531657e_64bf_373c_b89d_f3559147f514 = function(scope, event) {
		oninitFinal_Kpi_Appraisal(scope, event);
	}
	$scope.fn_417e5608_4ed9_3415_8483_a4531d22e743 = function(scope, event) {
		onInitRL5SideNav(scope, event);
	}
	$scope.fn_d7a7fd2c_f512_3df7_a588_aff76dca2ad4 = function(scope, event) {
		onInitMemberProfilePage(scope, event);
	}
	$scope.fn_29e87579_6809_3284_9d7d_0e9d8875643f = function(scope, event) {
		onInitCorporateKPIPage(scope, event);
	}
	$scope.fn_9c3e6a9a_ec35_3034_9779_9ba79fd77db0 = function(scope, event) {
		clickBehTab(scope, event);
	}
	$scope.fn_5257a04f_b832_3499_8570_008b2022aa40 = function(scope, event) {
		onintiKPISettingDetail(scope, event);
	}
	$scope.fn_314aae28_5369_35e2_9eb6_af9ac0a55ea2 = function(scope, event) {
		clickCompetencyNav(scope, event);
	}
	$scope.fn_374b5156_c53a_36d9_ad7a_57936e74846c = function(scope, event) {
		onInitTalentPool(scope, event);
	}
	$scope.fn_7ee605aa_e782_3a6a_b2b8_ec62f583c261 = function(scope, event) {
		onInitKPIManagementImportDrawer(scope, event);
	}
	$scope.fn_6f29e3c9_8160_3d9b_97a8_c35fc68cca58 = function(scope, event) {
		oninitKPTSetting(scope, event);
	}
	$scope.fn_fb7a3172_4dc3_3f7e_a755_73ce31c4f52a = function(scope, event) {
		onInitProgram(scope, event);
	}
	$scope.fn_2455ca88_e0a0_3275_8b0b_9237c637c0ea = function(scope, event) {
		onInitKPICascadePage(scope, event);
	}
	$scope.fn_0a523723_7fb4_30fa_86f5_b5129389f787 = function(scope, event) {
		onInitRM002_03HistoryJob(scope, event);
	}
	$scope.fn_09bdc96c_f74b_385c_93be_36added16fd0 = function(scope, event) {
		clickBehaviorSelfEvalu(scope, event);
	}
	$scope.fn_472a7fb4_2164_3518_9219_aee327c9d4b2 = function(scope, event) {
		initLoadBenefit(scope, event);
	}
	$scope.fn_427106b3_1102_35f3_ab31_a2ebe02b6009 = function(scope, event) {
		inintTrainingRoadmap(scope, event);
	}
	$scope.fn_2d503caf_99bb_37b5_b4a6_6b01e4177f29 = function(scope, event) {
		stateGoApplication(scope, event);
	}
	$scope.fn_3f83d22d_b02f_3c0a_a933_0b8ca89ece0a = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_17555bf0_24cb_3380_b9d3_a9dea989b4ae = function(scope, event) {
		initialSearchableApplication(scope, event);
	}
	$scope.fn_6168a521_8b08_3f3a_a139_6807bcf215ae = function(scope, event) {
		handle_kpiType(scope, event);
	}
	$scope.fn_54fd0b56_4a94_300f_b4b1_ebb2fcd35d3c = function(scope, event) {
		onInitRM004_05ExpectedProductivity(scope, event);
	}
	$scope.fn_1fe814ef_8395_340a_9407_3adecfabcfb5 = function(scope, event) {
		onInitKPIMasterSetUp(scope, event);
	}
	$scope.fn_c11c625f_a0ac_397e_84c7_9f8c156dbea3 = function(scope, event) {
		RM002_03btnView(scope, event);
	}
	$scope.fn_9fbc834f_1207_37a5_827d_520de7c73dd8 = function(scope, event) {
		requireFieldCreateInternship(scope, event);
	}
	$scope.fn_981b31c8_02ce_3f3a_aeb5_c0e0d40ee304 = function(scope, event) {
		loginGetCookie(scope, event);
	}
	$scope.fn_1f519cc0_d592_3506_b049_fead04de7aeb = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_60cc6a2a_7d95_335e_ac0f_b69bb982133c = function(scope, event) {
		checkResignDateComplete(scope, event);
	}
	$scope.fn_416bc7be_f6c2_3454_a4c4_87f548be659f = function(scope, event) {
		clickCompetencyNav(scope, event);
	}
	$scope.fn_2d0fcc20_d70b_3937_b4c1_539e521f33e3 = function(scope, event) {
		initExpenseReq(scope, event);
	}
	$scope.fn_2432d78b_b4fb_36da_a077_0175c477c663 = function(scope, event) {
		initPeerReviewAddSetting(scope, event);
	}
	$scope.fn_2905be6e_964f_38cb_8a26_65a80e55650d = function(scope, event) {
		onInitKPITopicDetailDrawer(scope, event);
	}
	$scope.fn_8be5cdf9_8774_37ea_b6ab_03fa2c2ae8a1 = function(scope, event) {
		initialKPI(scope, event);
	}
	$scope.fn_4e1be7f8_d192_3030_af5f_7b35ce1745cd = function(scope, event) {
		initSetPerformanceBaseline(scope, event);
	}
	$scope.fn_1a4465e9_1d70_345b_aed4_851c80dc69ee = function(scope, event) {
		setUpVersionControl(scope, event);
	}
	$scope.fn_fc45fb5b_8166_38a9_b48b_23490deccfc0 = function(scope, event) {
		checkingCallbackADFS(scope, event);
	}
	$scope.fn_a367b3d6_3956_3cd1_8bd9_1061f46ccb04 = function(scope, event) {
		sendValueSelectMasterSetup(scope, event);
	}
	$scope.fn_43a18ca8_b3b8_376b_b8bc_549b05e05e12 = function(scope, event) {
		initkpiIndividaulSession(scope, event);
	}
	$scope.fn_2f4df429_3632_37cc_9087_712660fa38de = function(scope, event) {
		onintEmpInformationApprove(scope, event);
	}
	$scope.fn_6d2ee54b_f5a3_37bd_a808_9193ffc9da99 = function(scope, event) {
		gotoPageServiceTicketV4(scope, event);
	}
	$scope.fn_65aad5ca_ab95_345f_ae9a_e4b7bd9bb9ae = function(scope, event) {
		clickBehImgStats(scope, event);
	}
	$scope.fn_0cd353c0_7027_3784_bd6b_02be25d2054d = function(scope, event) {
		displayLanguage(scope, event);
	}
	$scope.fn_eb9003ce_c3c5_3055_83b1_5c7e6189c52b = function(scope, event) {
		oninitKPI_Appraisal(scope, event);
	}
	$scope.fn_662c12c2_fe4b_3c74_910a_ef193ca8a872 = function(scope, event) {
		initPeerSelectAssessor(scope, event);
	}
	$scope.fn_9587612d_3008_3bf1_bfd6_77953c14c2a8 = function(scope, event) {
		onInitCloseAssessment(scope, event);
	}
	$scope.fn_05e0b6c7_d198_3574_bbb6_3948820a879b = function(scope, event) {
		filterClearRequest(scope, event);
	}
	$scope.fn_d5cfdd23_e488_345f_bcaa_e7f2b2072888 = function(scope, event) {
		initOTReqHR(scope, event);
	}
	$scope.fn_5d685e95_0167_312d_8962_82531ff6c25d = function(scope, event) {
		onInitMonitorValueReviewScreen(scope, event);
	}
	$scope.fn_3a535c85_4747_309f_a6c6_e0184a20a120 = function(scope, event) {
		onitEmergencyLoanWelfare(scope, event);
	}
	$scope.fn_7f77b33d_8efd_3b61_b05e_c569975744bc = function(scope, event) {
		clickImgLayar2(scope, event);
	}
	$scope.fn_0e869276_0035_35d9_ab26_23dcdaeb1ae2 = function(scope, event) {
		gotoPageOpenManpower(scope, event);
	}
	$scope.fn_94f72f56_40e2_3e44_8571_7c5f82d668f2 = function(scope, event) {
		handle_kpiJobType(scope, event);
	}
	$scope.fn_1c06e28a_d84f_306b_8d19_8bea27eb930b = function(scope, event) {
		onInitKPIReview(scope, event);
	}
	$scope.fn_7636be73_8ece_308b_a3ac_c93d62fa96d7 = function(scope, event) {
		clickCompetSelfEvalu(scope, event);
	}
	$scope.fn_ef109b22_a355_3002_b0a4_76a2f81fcfce = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_a0227878_e7de_3875_9b06_7d3367bb43bb = function(scope, event) {
		showRehis(scope, event);
	}
	$scope.fn_002498b7_2e18_3f4d_9eed_c115b9adf989 = function(scope, event) {
		onInitAssessmentResults(scope, event);
	}
	$scope.fn_7792a080_ecd9_3007_a673_60fc99bd3367 = function(scope, event) {
		gotoPageOpenManpowerV4(scope, event);
	}
	$scope.fn_e94a8635_3562_3191_a751_fe0e45394ec9 = function(scope, event) {
		clickImgStats(scope, event);
	}
	$scope.fn_a547df13_a3a9_3664_9995_21ff4a3678d8 = function(scope, event) {
		initialBenefit(scope, event);
	}
	$scope.fn_3c4c7457_2563_3c97_ba3a_99043c583ddc = function(scope, event) {
		togglePassword(scope, event);
	}
	$scope.fn_8fef65ed_eaf3_373d_964b_934516db1f25 = function(scope, event) {
		initLogin(scope, event);
	}
	$scope.fn_ffa528e4_e55e_3b7f_bdc8_fe5b16408f19 = function(scope, event) {
		checkingCallbackADFS(scope, event);
	}
	$scope.fn_3dfb1265_c68c_363d_94db_f0d2af537612 = function(scope, event) {
		onInitRM002_02ValidateField(scope, event);
	}
	$scope.fn_410a14da_f032_35aa_9014_709b84977f2d = function(scope, event) {
		initManPowerPlanning2(scope, event);
	}
	$scope.fn_b72db0be_fdea_3853_88f4_5cbe6c37e492 = function(scope, event) {
		onInitRM004_06(scope, event);
	}
	$scope.fn_77800d38_0dc2_3055_99a5_dce975bcb2de = function(scope, event) {
		oninitPerformanceManagement(scope, event);
	}
	$scope.fn_6e61569e_f64e_3cbb_8d8d_a168d8010a6f = function(scope, event) {
		onInitRM004_08(scope, event);
	}
	$scope.fn_86d1ddf0_ff65_342a_b9d3_3f79c050f8ca = function(scope, event) {
		onitEcxessClaim(scope, event);
	}
	$scope.fn_d9c88313_5fca_3c56_a85a_d16f32235f39 = function(scope, event) {
		oninitPerformanceCompare(scope, event);
	}
	$scope.fn_0a3baa15_b4fd_3f2e_b5a2_c2c1b0ecf46a = function(scope, event) {
		onInitTalentProgressPage(scope, event);
	}
	$scope.fn_8f2649bc_4dd8_3965_b86d_a36ad6650662 = function(scope, event) {
		oninitPerformanceSettingAdd(scope, event);
	}
	$scope.fn_ac6e8de7_388c_3ea0_9639_7faeb5185f22 = function(scope, event) {
		generateExcel(scope, event);
	}
	$scope.fn_0fb2bd1e_5276_3079_9f4e_55934e106a59 = function(scope, event) {
		oninit_kpi_appraisal(scope, event);
	}
	$scope.fn_b3bc32dd_aa0b_3b5c_88a5_beddbf5a753c = function(scope, event) {
		doxStatus(scope, event);
	}
	$scope.fn_ad9d275b_5567_3c73_ad72_d010d235456b = function(scope, event) {
		onInitKPIManagementPage(scope, event);
	}
	$scope.fn_f5aa88d8_316b_35ee_b9a7_848bf15c40a7 = function(scope, event) {
		onInitSendAnAssessment(scope, event);
	}
	$scope.fn_03577aa0_164d_3d9a_a420_9ec88aadad3a = function(scope, event) {
		onInitRM004_06ExpectedProduct(scope, event);
	}
	$scope.fn_f6e4e79c_beba_3e4e_83ea_2740662bbc31 = function(scope, event) {
		if(timeDateChange(scope, event) === false) return;
		var state = scope.State;
		var data = {"uniqueId":"B14439306-926285bd-75c5-4eea-9c12-dcd97424183c","flowName":"get_time_attendance_shift","object":{"get_time_attendance_shift_input":{"employee_code":state.employee_code,"attendance_date":state.time_date,"organize_id":state.organize_id}}}
		microflowFac.doFlowAction(data, scope)
		.then(function(data) {
			if(afterGetTAshift(scope, event, data, null) === false) return;
		}, function(error) {
			if(afterGetTAshift(scope, event, null, error) === false) return;
		});
	}
	$scope.fn_9d8feff3_0f04_3bef_9ee3_fc5386d02c3b = function(scope, event) {
		if(timeDateChange(scope, event) === false) return;
		var state = scope.State;
		var data = {"uniqueId":"D9988701-9cdee331-f1fa-4a09-b393-88e809c1547a","flowName":"get_employee_shift","object":{"get_employee_shift_input":{"employee_code":state.employee_code,"organize_id":state.organize_id,"date":state.time_date,"ship_name_code":""},"inputCheckConstantGetEmp":{"constant_name":""}}}
		microflowFac.doFlowAction(data, scope)
		.then(function(data) {
			if(afterGetEmployeeShift(scope, event, data, null) === false) return;
		}, function(error) {
			if(afterGetEmployeeShift(scope, event, null, error) === false) return;
		});
	}
	$scope.fn_e2c21502_874f_3732_8a10_bfe66a96bec9 = function(scope, event) {
		if(beforeCheckTimeAdjustCanReq(scope, event) === false) return;
		var state = scope.State;
		var data = {"uniqueId":"F8828546-8327b234-ea58-4d01-9d31-9168d46f8f84","flowName":"check_time_adjust_can_req","object":{"check_time_adjust_can_req_input":{"time_date":state.time_date_input,"organize_id":state.organize_id}}}
		microflowFac.doFlowAction(data, scope)
		.then(function(data) {
			if(afterCheckTimeAdjustCanReq(scope, event, data, null) === false) return;
		}, function(error) {
			if(afterCheckTimeAdjustCanReq(scope, event, null, error) === false) return;
		});
	}
	$scope.fn_7434736a_50ff_351f_af13_842b827e5f6a = function(scope, event) {
		initialFormPage(scope, event);
	}
	$scope.fn_94fc0721_137c_3cf6_ba78_50865015db98 = function(scope, event) {
		onInitRM002_postjob(scope, event);
	}
	$scope.fn_5307c79d_1d3f_354f_934d_8f1dd388a8a9 = function(scope, event) {
		initTimeAdjustApprove(scope, event);
	}
	$scope.fn_57087ced_4f35_38f8_90d4_d9362d35156a = function(scope, event) {
		onInitRM002JobDetail(scope, event);
	}
	$scope.fn_f20abc2c_02f5_341b_a85c_70a9719eb554 = function(scope, event) {
		onInitSelectQuestionnaire(scope, event);
	}
	$scope.fn_234d79d8_edd4_38ce_a438_dc71ed37fe7a = function(scope, event) {
		stateGoApplication(scope, event);
	}
	$scope.fn_fbe32ff6_cfb9_3568_b5ce_74d7f45ef4f5 = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_93736f72_dda9_32ea_a55d_616c458e0559 = function(scope, event) {
		addIndividualKpi(scope, event);
	}
	$scope.fn_652b2b00_0464_3a43_804c_910b0bbb699d = function(scope, event) {
		oninitTimeRecordDaily(scope, event);
	}
	$scope.fn_8eec722e_7c4b_390e_ba14_2b14e099de46 = function(scope, event) {
		onInitBidCourseHistoryPage(scope, event);
	}
	$scope.fn_adbe84a2_6bba_3558_a154_c86e8e96e2c1 = function(scope, event) {
		clickBehImgStats(scope, event);
	}
	$scope.fn_61be6fb7_b367_3a34_baf1_d23aad0b938d = function(scope, event) {
		initTrainingRequestAdd(scope, event);
	}
	$scope.fn_9258c480_bca1_338e_b9d7_abd4e2eb5094 = function(scope, event) {
		oninitEmplyeesCertificateGSB(scope, event);
	}
	$scope.fn_ae7f81b1_add7_34fc_a997_250ce97752d7 = function(scope, event) {
		showDetailActionPlanCourse(scope, event);
	}
	$scope.fn_45c8924c_0293_3be6_bc40_09aecdb65df2 = function(scope, event) {
		onitOtherBenefitHR(scope, event);
	}
	$scope.fn_34d1a769_9657_3c5f_bfce_43dbc2a7365a = function(scope, event) {
		onInitOverTimeReq(scope, event);
	}
	$scope.fn_f1dcc821_2783_35f1_bd43_8e2f566e259d = function(scope, event) {
		onInitKPISelfReview(scope, event);
	}
	$scope.fn_d955359b_f329_33ba_a36e_68f466d85ffb = function(scope, event) {
		onInitRMProbation(scope, event);
	}
	$scope.fn_6df4b448_65c8_3887_8e18_e5327b6121d6 = function(scope, event) {
		initTaxInformation(scope, event);
	}
	$scope.fn_f41156f8_0b06_3145_9162_6de7fd94a77f = function(scope, event) {
		stateGoPreviousPage(scope, event);
	}
	$scope.fn_af39295f_0446_36e1_af27_65acbb7e1ff4 = function(scope, event) {
		showFilter(scope, event);
	}
	$scope.fn_b7267772_a67d_3e93_8617_37dd41ce873b = function(scope, event) {
		initKPIReviewAppraiserPage(scope, event);
	}
	$scope.fn_17cd8091_d074_34bc_8625_c712d36c1ab2 = function(scope, event) {
		goKpiFinalAppraisal(scope, event);
	}
	$scope.fn_29bcc64d_6f17_399a_832e_a2448b9f5562 = function(scope, event) {
		initManageOrgChartR2(scope, event);
	}
	$scope.fn_deb2ef4c_fcff_31c0_bc28_4e8acc60c7a5 = function(scope, event) {
		validate_request_time(scope, event);
	}
	$scope.fn_061924ff_e64f_360c_944e_7779fb88ddf8 = function(scope, event) {
		onInitBidCourseDetailPage(scope, event);
	}
	$scope.fn_47d6fac2_d649_3fb9_acf8_b38714770f29 = function(scope, event) {
		orgNodeChange(scope, event);
	}
	$scope.fn_d1340262_7bad_30ca_a173_20a3e606f573 = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_7ae80862_9460_37b8_8617_4779bc9796ff = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_2288fb72_ff05_3535_9b71_ba62300364db = function(scope, event) {
		initManpowerFlow(scope, event);
	}
	$scope.fn_3ff2307b_f1e8_30fc_9d22_45bbb7071c53 = function(scope, event) {
		initLogin(scope, event);
	}
	$scope.fn_e90879b5_64b6_3153_894b_b878ac55a2ee = function(scope, event) {
		onInitKPISelfReviewNew(scope, event);
	}
	$scope.fn_0b8b110c_d3d0_3da7_89e4_04bb771179f3 = function(scope, event) {
		oninitViewActionPlan(scope, event);
	}
	$scope.fn_147d2c27_65bf_396b_b30a_d49513387473 = function(scope, event) {
		addToCancelReq(scope, event);
	}
	$scope.fn_8f33af65_5580_314c_9d9b_9906c5297e35 = function(scope, event) {
		initialT2Transaction(scope, event);
	}
	$scope.fn_536a2d7c_c60d_35ff_8e37_d2679d8c623a = function(scope, event) {
		initKpiSetting(scope, event);
	}
	$scope.fn_e7f4a4f8_b950_3726_8eee_f7b9a6fc81e5 = function(scope, event) {
		initkpiIndividaulSession(scope, event);
	}
	$scope.fn_4fa1b7f1_a3dd_3bb0_af5d_f25e59e4ef4d = function(scope, event) {
		loginGetCookie(scope, event);
	}
	$scope.fn_1010779d_5858_3512_a1c4_567c9338093a = function(scope, event) {
		showRehis(scope, event);
	}
	$scope.fn_99b4a633_87a6_3c5e_bb65_1f1a71ebea8b = function(scope, event) {
		initTimeAdjustHistory(scope, event);
	}
	$scope.fn_dfa3240f_25c3_34a1_937f_e92533831eb9 = function(scope, event) {
		goToCreateProgram(scope, event);
	}
	$scope.fn_2d4cc878_be32_3b96_bd59_5107b2824b0a = function(scope, event) {
		clickCompetencyNav(scope, event);
	}
	$scope.fn_8de55074_3c5f_37cd_aa0b_01d9631c0978 = function(scope, event) {
		initKPIEvaluationPage(scope, event);
	}
	$scope.fn_596b824c_91a7_3d59_8ddf_7df6842f148f = function(scope, event) {
		onInitRM004_05(scope, event);
	}
	$scope.fn_a6e0bc2e_bf5a_3f77_a0d4_57818cac5b9c = function(scope, event) {
		onInitKPIReviewRatingDrawer(scope, event);
	}
	$scope.fn_bace5862_c56c_3f0d_975d_fa0d66336395 = function(scope, event) {
		OniInitExternalTrainingReq(scope, event);
	}
	$scope.fn_5c456c09_9e1e_3640_abd6_4b0fa30462f4 = function(scope, event) {
		onintPerformanceHRSetup(scope, event);
	}
	$scope.fn_6f91c498_cc30_38d9_ae70_0b2152cf5244 = function(scope, event) {
		backToOrgChart(scope, event);
	}
	$scope.fn_41a23893_b28f_312f_9cb3_e2a06009ce01 = function(scope, event) {
		onInitTeamBenefitOTPScreen(scope, event);
	}
	$scope.fn_64bab3cb_1a1f_3e37_b29a_ee2219a4c618 = function(scope, event) {
		gotoPageTalent(scope, event);
	}
	$scope.fn_783408b9_61b4_3f26_b444_f28b1b3afc69 = function(scope, event) {
		linkBackToHeaderCompe(scope, event);
	}
	$scope.fn_af9c4cca_aa7b_3fff_933a_f357b36b77dc = function(scope, event) {
		getDetailShowModal(scope, event);
	}
	$scope.fn_e6552285_076d_3d45_9b68_4712622b45b3 = function(scope, event) {
		initiCreateInternRequest(scope, event);
	}
	$scope.fn_5d4949c8_f636_38a1_8448_124d3f1456e8 = function(scope, event) {
		handler(scope, event);
	}
	$scope.fn_f0292d30_b29f_374b_962d_131600225283 = function(scope, event) {
		onInitKPISettingProgressDrawer(scope, event);
	}
	$scope.fn_f10d196d_2fd1_3f31_9b6e_05d1e84a4c40 = function(scope, event) {
		searchPeerAssessor(scope, event);
	}
	$scope.fn_a9773711_21a5_35c0_843b_f707e5e7de15 = function(scope, event) {
		onInitfinalResult(scope, event);
	}
	$scope.fn_7a8edeac_8c01_3afb_a29a_aac7a905dc71 = function(scope, event) {
		intern_requistion_detail(scope, event);
	}
	$scope.fn_088c3f48_5d07_3b76_9a86_33ab7cc17a40 = function(scope, event) {
		onitiPerformanceBaseLineSetup(scope, event);
	}
	$scope.fn_542bbcac_31d7_3144_9557_c92fa51262b8 = function(scope, event) {
		initPeerReviewAdd(scope, event);
	}
	$scope.fn_6f0612e6_32d6_3087_ac60_203654ac5339 = function(scope, event) {
		initLearning(scope, event);
	}
	$scope.fn_6c159d2a_65c8_3737_997c_662a46b0424a = function(scope, event) {
		validateSubmitResign(scope, event);
	}
	$scope.fn_6bf9cff6_e58c_31df_9a1a_98ae79df89f4 = function(scope, event) {
		initialServiceTicketMenu(scope, event);
	}
	$scope.fn_df22778a_2d36_3cb9_b749_e18cb5bd0052 = function(scope, event) {
		onInitRM002_02(scope, event);
	}
	$scope.fn_5f72f692_048d_3899_9031_1f82408bb4b9 = function(scope, event) {
		onInitcareer_PB(scope, event);
	}
	$scope.fn_4f6d832f_2df9_351f_9442_ff0eb0615fc8 = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_5c059da7_f53f_3677_9aa9_a155591db433 = function(scope, event) {
		onitManageManageOtherBenefitHR(scope, event);
	}
	$scope.fn_89b67ead_ce28_30fa_9eac_b2de3cbf6cfc = function(scope, event) {
		switchExpenseRequestPage(scope, event);
	}
	$scope.fn_0c4e527b_744f_35db_a6f8_84a98ead3f14 = function(scope, event) {
		onInitReviewAndManageReviewerScreen(scope, event);
	}
	$scope.fn_af52ae69_2c7b_3458_a865_7801fdda291a = function(scope, event) {
		onInitPRMonitorReviewerScreen(scope, event);
	}
	$scope.fn_0fc138ec_df17_34ff_a73b_242fe33e24ef = function(scope, event) {
		clickKpiTab(scope, event);
	}
	$scope.fn_3010c790_bdef_37ff_a25a_efb4c05c43d3 = function(scope, event) {
		onInitRL5_compendancy(scope, event);
	}
	$scope.fn_533d9bd4_d7dd_37b5_827a_ce992339377f = function(scope, event) {
		initManageOrgChartDetail(scope, event);
	}
	$scope.fn_8e23a7f7_ed15_39d9_8159_3ecb649a9f2d = function(scope, event) {
		initKPIEmployeeEvaluationProfilePage(scope, event);
	}
	$scope.fn_566eca1e_f08a_3f54_a3c2_cc9077113942 = function(scope, event) {
		initTimeAdjustRequestPage(scope, event);
	}
	$scope.fn_02ed9f5c_0666_32b7_826e_31b97bd2ae08 = function(scope, event) {
		initManPowerPlanning(scope, event);
	}
	$scope.fn_3e78070d_b0c9_3544_b79e_b55e1ef23cb8 = function(scope, event) {
		onInitKPIAppraisalManage(scope, event);
	}
	$scope.fn_e0836526_c59f_3fd4_9458_063cb3b92f22 = function(scope, event) {
		onInitCorporateKPISearchTopicPage(scope, event);
	}
	$scope.fn_31f83122_e359_32f5_a66c_36a5622c2bec = function(scope, event) {
		onInitKPIManagementDashboardPage(scope, event);
	}
	$scope.fn_6bd26702_b5df_3d7d_ad67_4090829e979c = function(scope, event) {
		oninitTeamBenefitRequest(scope, event);
	}
	$scope.fn_c2a0b41d_13f1_3ea9_b4f5_d2cdf654614e = function(scope, event) {
		initPeerAppraisalQuestion(scope, event);
	}
	$scope.fn_8acb9bb5_0edb_3643_8740_38d2391daa13 = function(scope, event) {
		onInitPBBellCurveChart(scope, event);
	}
	$scope.fn_c94a1b86_37e5_34f0_bd95_38bddba3659e = function(scope, event) {
		initStepWizard(scope, event);
	}
	$scope.fn_e0939032_905d_34b0_9c68_ff74a042e52f = function(scope, event) {
		initialPayslip(scope, event);
	}
	$scope.fn_08e1b0a7_aa03_38f4_a5e5_cc5ab6356174 = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_5807427c_bb8c_310d_a183_e73e7fcff826 = function(scope, event) {
		onInitRM001_01PublicRelations(scope, event);
	}
	$scope.fn_ec9067c5_4066_39f6_87c9_c817461b6b45 = function(scope, event) {
		gotoPageServiceTicket(scope, event);
	}
	$scope.fn_4267727b_8033_3194_be11_7dd295398d25 = function(scope, event) {
		ClearRequest(scope, event);
	}
	$scope.fn_bec615b1_1118_308e_84c5_c6b913c0c672 = function(scope, event) {
		openModalConfirm(scope, event);
	}
	$scope.fn_377707a1_afe3_3f9d_b538_291f4653d767 = function(scope, event) {
		initTalentHeaderPage(scope, event);
	}
	$scope.fn_3f23cadc_36a1_32b3_b24c_c49505318dbe = function(scope, event) {
		onInitBidCourseSection(scope, event);
	}
	$scope.fn_ab865b86_7cf7_39da_b701_c4b4a1441f90 = function(scope, event) {
		cancelRequistion(scope, event);
	}
	$scope.fn_10dcaf4c_579d_3847_840a_5ae86401d810 = function(scope, event) {
		gotoPageForm(scope, event);
	}
	$scope.fn_d7a2d83f_c979_3d07_8e36_2b1bac84db9e = function(scope, event) {
		onintSwapShiftApprove(scope, event);
	}
	$scope.fn_d2585005_30ec_3f6d_acc5_711d78d0d2bf = function(scope, event) {
		onInitRM002_02_Excutive(scope, event);
	}
	$scope.fn_1d17e743_8540_3bd2_aea1_5a2c633fea84 = function(scope, event) {
		onInitReviewSettingApprove(scope, event);
	}
	$scope.fn_046ab951_295a_3c05_b35e_241ae61069ad = function(scope, event) {
		oninitnavbar_gsb(scope, event);
	}
	$scope.fn_96491a18_70b1_35c9_8ef7_cfd22ac7750c = function(scope, event) {
		initGoJS(scope, event);
	}
	$scope.fn_e7a3a603_5b41_3ae1_a8ff_5e1ecac2d2ef = function(scope, event) {
		onInitRM003_07(scope, event);
	}
	$scope.fn_e25efb9a_76a4_338b_9384_a372a3c36f76 = function(scope, event) {
		clickBackCompetemain(scope, event);
	}
	$scope.fn_9551bf3a_4912_39bc_8473_e5e0f22dcc7f = function(scope, event) {
		onInitRM001_01Screen(scope, event);
	}
	$scope.fn_861fde68_dce1_38bc_b5e9_358f82eabc7a = function(scope, event) {
		onInitPBCompensationReview(scope, event);
	}
	$scope.fn_db5f03a5_9b96_35c4_ac75_182a1bdd83e8 = function(scope, event) {
		oninitSelfCompetency(scope, event);
	}
	$scope.fn_d20df437_2789_3d5c_93e3_f338001d9773 = function(scope, event) {
		OnInitActionPlanPage(scope, event);
	}
	$scope.fn_4b02766f_34d6_36a9_92fa_ed5f396c5523 = function(scope, event) {
		changeCompetencyDeveloped(scope, event);
	}
	$scope.fn_c6bd0fd4_23b7_3b3d_94a1_af0721e72d9f = function(scope, event) {
		oninitJob_Opening(scope, event);
	}
	$scope.fn_6e561053_f96d_3f2d_ba90_345b7d880212 = function(scope, event) {
		clickBehImgLayar2(scope, event);
	}
	$scope.fn_9a6bb1f9_b8d3_3783_8755_f43c875ea66d = function(scope, event) {
		onInitRM004_06UserInfo(scope, event);
	}
	$scope.fn_11a8d323_e345_3cf7_8a29_59300f54a7df = function(scope, event) {
		cancelInternshipReq(scope, event);
	}
	$scope.fn_c871520d_0880_32bc_8398_331f4f07163f = function(scope, event) {
		validate_request_time(scope, event);
	}
	$scope.fn_f981bb2c_7e89_364e_b2a5_21d6b8984bb3 = function(scope, event) {
		Final_Kpi_Appraisal_Changevalue(scope, event);
	}
	$scope.fn_9447a200_c68d_3f81_b98d_948852e0751c = function(scope, event) {
		gotoPageManageMyTeam(scope, event);
	}
	$scope.fn_b46a0923_e908_3d2c_8277_63a838bc7848 = function(scope, event) {
		RM002_03onChangePositionSelect(scope, event);
	}
	$scope.fn_a2a92f29_5705_340e_8f75_b5ba4d972df6 = function(scope, event) {
		onInitCreateProgram(scope, event);
	}
	$scope.fn_7e4d34a0_4ca2_320f_9264_aabd61a53e7a = function(scope, event) {
		gotoPageKPIManagement(scope, event);
	}
	$scope.fn_8ec910ef_67b7_3e91_9bcc_dc4a99a82571 = function(scope, event) {
		openAdfsLoginDeep(scope, event);
	}
	$scope.fn_c959f548_c5cc_366b_b05b_2941a077c091 = function(scope, event) {
		onInitRM004_04(scope, event);
	}
	$scope.fn_aae0bc0d_c72d_3e75_a932_b6ca217b4d24 = function(scope, event) {
		initToDo(scope, event);
	}
	$scope.fn_64661fdd_5653_36ff_ae59_95fd6f9d4e4b = function(scope, event) {
		oninitCompetenciesManagement(scope, event);
	}
	$scope.fn_1a590ed9_11b8_3cf2_92ff_d62386350618 = function(scope, event) {
		change_face(scope, event);
	}
	$scope.fn_5b700130_18dc_36b0_a660_75a60b17e7e7 = function(scope, event) {
		initTrainingRequestHr(scope, event);
	}
	$scope.fn_bb4c737c_aabf_35cc_ab30_05f7c19b8b82 = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_e2d62bb2_29e6_3f47_b87d_22e92f93461e = function(scope, event) {
		oninitConfirmClaimPayRoll2(scope, event);
	}
	$scope.fn_b766b7c1_7fb1_3dc6_9b04_348e5d7d0027 = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_70c99e15_4174_3ad8_885d_f8dd9ecb7f93 = function(scope, event) {
		searchMenuFinal_KPI(scope, event);
	}
	$scope.fn_cef20c69_7a9f_39cb_bdd2_b17d90e26fd0 = function(scope, event) {
		onInitRM004_06Comment(scope, event);
	}
	$scope.fn_04d7a2d9_10e6_336b_b807_37bcb6cf09d4 = function(scope, event) {
		initialESS(scope, event);
	}
	$scope.fn_9692d882_bc9f_31f3_af2f_67bf786346e1 = function(scope, event) {
		req_menu(scope, event);
	}
	$scope.fn_1b8851a1_1261_3e36_bcd6_78637828aeae = function(scope, event) {
		clickCareerTab(scope, event);
	}
	$scope.fn_ad8de0b3_16a8_3f01_941a_45e2e651985e = function(scope, event) {
		oninitEmCertificateApprove(scope, event);
	}
	$scope.fn_cd0dcd5d_8d1c_3561_ba94_76dbf15f4589 = function(scope, event) {
		callMf_KPI_Review(scope, event);
	}
	$scope.fn_d0b920d2_9609_349f_847b_571c39d29475 = function(scope, event) {
		oninitKPI_Review(scope, event);
	}
	$scope.fn_af5f27dd_1e60_3f97_8e7e_96568038bdf4 = function(scope, event) {
		onInintValueReviewPage(scope, event);
	}
	$scope.fn_64e2e49a_cf63_3a15_bb0b_45af90ffc6cb = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_858ff433_e971_3a63_add8_e21de919e5c3 = function(scope, event) {
		oninitNavbar(scope, event);
	}
	$scope.fn_1e8869ce_40da_3d20_bdd0_5bd404a02adc = function(scope, event) {
		onInitKPIMasterSetupDrawer(scope, event);
	}
	$scope.fn_f35cd425_4a69_3943_bf21_de18fcfbca86 = function(scope, event) {
		onInitFunctionalKPIPage(scope, event);
	}
	$scope.fn_9c94058a_2908_3576_8696_21fb657f005b = function(scope, event) {
		clickBehImgLayar2(scope, event);
	}
	$scope.fn_a0e0d6af_7364_3cfb_a917_037c1bc06c96 = function(scope, event) {
		onInitRM004_06Evaluate(scope, event);
	}
	$scope.fn_a5dd6787_cc9b_385b_844f_a313df82a8db = function(scope, event) {
		oninit_excess_claim_HR(scope, event);
	}
	$scope.fn_da0fadaf_3267_3767_a98b_b483ff50d423 = function(scope, event) {
		onInitRM004_04ScaleSetup(scope, event);
	}
	$scope.fn_fd2a34e7_e64c_3257_8765_614da462ef3e = function(scope, event) {
		closeAppraisalThenGoToCloseAppraisalPage(scope, event);
	}
	$scope.fn_cccba25f_825f_34ba_a358_90e31d570121 = function(scope, event) {
		onInitPBPerformanceManageDetail(scope, event);
	}
	$scope.fn_3791544f_b185_3f49_b488_b90c573940c3 = function(scope, event) {
		oninintKP005_01(scope, event);
	}
	$scope.fn_e07fc90e_efb3_33af_b636_4ddb1d09e2be = function(scope, event) {
		clickImgStats(scope, event);
	}
	$scope.fn_33534ebe_4eda_3b42_9776_5fd2f7a16c74 = function(scope, event) {
		cancelInternshipReq(scope, event);
	}
	$scope.fn_22e67908_4f01_3efe_aa79_ac4fd89a8fe6 = function(scope, event) {
		stateGoApplication(scope, event);
	}
	$scope.fn_86507f38_b190_39a1_a967_4fc6e5d80980 = function(scope, event) {
		onInitKpiEvaluate(scope, event);
	}
	$scope.fn_f1927c07_3014_3cc0_9875_dd7d0d856f31 = function(scope, event) {
		loginGetCookie(scope, event);
	}
	$scope.fn_e603bc2b_24ec_3a02_852a_04692698296b = function(scope, event) {
		showFilter(scope, event);
	}
	$scope.fn_53496491_71fe_3f4e_8531_e2a0cedb7129 = function(scope, event) {
		oninitCareerCompetency(scope, event);
	}
	$scope.fn_b68b65f0_0749_3b7b_ae53_23cf57efce95 = function(scope, event) {
		onInitKPITypeCommentDrawer(scope, event);
	}
	$scope.fn_c4d06f75_1de0_348c_986f_4e40aa288b3b = function(scope, event) {
		gotoPageOpenPageBackend(scope, event);
	}
	$scope.fn_8c811e9c_65bc_3329_b182_988f64238ab1 = function(scope, event) {
		searchMenuOrganize(scope, event);
	}
	$scope.fn_874e6553_a0b7_32c7_85f2_de4b19cccf48 = function(scope, event) {
		onInitValueReviewDashboardScreen(scope, event);
	}
	$scope.fn_df7ae763_9835_310c_97f2_188a67e79aef = function(scope, event) {
		initLogin(scope, event);
	}
	$scope.fn_ee13b9cf_66c5_3a58_86cb_2ebf7dcb25da = function(scope, event) {
		checkingCallbackADFS(scope, event);
	}
	$scope.fn_a8737c4d_c1c9_3b1b_a5cf_9acaad918995 = function(scope, event) {
		onInitPBEmployeeDetail(scope, event);
	}
	$scope.fn_c5174385_dd51_3465_bac1_49a8b26e5579 = function(scope, event) {
		Filter(scope, event);
	}
	$scope.fn_e670ec16_b1b3_370a_8c4a_e3e36e881f91 = function(scope, event) {
		oninitCompetencySetting(scope, event);
	}
	$scope.fn_00c4412a_2f27_39cf_a0a0_ccd6eb21c96b = function(scope, event) {
		onInitJobOpening(scope, event);
	}
	$scope.fn_fc276a8b_71ff_3818_8d54_f0782987a66a = function(scope, event) {
		initPerformanceBaselineManage(scope, event);
	}
	$scope.fn_11d30e64_2ab3_3ddd_a14f_7d44ea81cda5 = function(scope, event) {
		oninitKPISelfEvaluatetion(scope, event);
	}
	$scope.fn_937a18b6_a900_3c37_b428_7997199f8063 = function(scope, event) {
		clickCompTab(scope, event);
	}
	$scope.fn_eb7c00a5_f870_3127_a6ca_84d75c5cc677 = function(scope, event) {
		onInitKLPBDetailScreen(scope, event);
	}
	$scope.fn_4669cfb7_e238_35d4_b26c_e205b3443b99 = function(scope, event) {
		clickBackBehaviormain(scope, event);
	}
	$scope.fn_551adc74_91c4_3754_984e_8ec72702b418 = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_2c60f3b1_eabb_3990_861d_8fb42bfc641f = function(scope, event) {
		onInitSelectMemberTalentPool(scope, event);
	}
	$scope.fn_6bc2799e_95cd_32b1_9a79_c171f4f78d58 = function(scope, event) {
		initPeerAppPage(scope, event);
	}
	$scope.fn_8d981c23_5ce7_3484_b04f_739a73c21c53 = function(scope, event) {
		onInitKPICreateTopicDrawer(scope, event);
	}
	$scope.fn_b33e0aa4_b166_3da8_bc6c_72a6246be203 = function(scope, event) {
		initTrainingReqHrView(scope, event);
	}
	$scope.fn_3865d804_e6d5_377e_bca8_5a85eabcc1ed = function(scope, event) {
		logout(scope, event);
	}
	$scope.fn_f3e35e06_5e6e_3c7c_b0bc_4628e86737ab = function(scope, event) {
		onitMenuServiceCenter(scope, event);
	}
	$scope.fn_69c4cea3_f05a_35c3_a8c6_37cb42bdc705 = function(scope, event) {
		initContacts(scope, event);
	}
	$scope.fn_9d2694a9_b0fe_30c7_abe1_4d3b515b19f0 = function(scope, event) {
		onInitRM004_06TopicPerfor(scope, event);
	}
	$scope.fn_d5d99d4a_76a7_3276_a676_7806c9198396 = function(scope, event) {
		stateGoApplication(scope, event);
	}
	$scope.fn_ed9bb8f7_d954_3e18_b496_6509553b1e43 = function(scope, event) {
		initialServiceTicketMenu(scope, event);
	}
	$scope.fn_2e021e25_6444_307c_ad17_4b82b0928608 = function(scope, event) {
		oninitEm_certificate_Appdetail(scope, event);
	}
	$scope.fn_d25df304_a671_30e8_b905_f3f958f6c9dd = function(scope, event) {
		RM002_03btnEdit(scope, event);
	}
	$scope.fn_c5edae5a_99c8_3b63_b97d_0f7b61b69a6b = function(scope, event) {
		oninintProbationHistory(scope, event);
	}
	$scope.fn_e65151d1_6316_3afc_b4fd_d89808f3da57 = function(scope, event) {
		onInitAssessmentResultsScoreSummary(scope, event);
	}
	$scope.fn_e7b0bd7a_3058_3b98_8b36_cef33ecf847b = function(scope, event) {
		goKpiAppraisal(scope, event);
	}
	$scope.fn_bf97b9f9_4496_37f5_811a_4ab2293fa313 = function(scope, event) {
		onInitSelectMember(scope, event);
	}
	$scope.fn_ae608ff7_2ed6_39dc_abeb_7afd85190f1b = function(scope, event) {
		showRehis(scope, event);
	}
	$scope.fn_abbe06db_3b97_39d7_b921_25634d051c6d = function(scope, event) {
		onitOtherBenefit(scope, event);
	}
	$scope.fn_1a927dd3_4cca_3dae_9604_660ea0243332 = function(scope, event) {
		onInitRM004_07(scope, event);
	}
	$scope.fn_a65e2580_a270_3729_aff4_16a0600b5c93 = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_23aa2ac9_07d1_3876_a4b7_93344f6b2088 = function(scope, event) {
		clickActionTab(scope, event);
	}
	$scope.fn_3389049b_790d_3bc7_82b4_dce624268cc5 = function(scope, event) {
		gotoPageManageOrgChart(scope, event);
	}
	$scope.fn_c1ae9713_7a8e_308c_b18e_031016b0d701 = function(scope, event) {
		initCareer(scope, event);
	}
	$scope.fn_48b3e773_4bf3_3bf8_86b6_176b5cbecbe2 = function(scope, event) {
		oninitCareerKPI(scope, event);
	}
	$scope.fn_dd36dfd5_ea92_340e_a3b0_e3656d12f89c = function(scope, event) {
		onInitKPIManagementPage(scope, event);
	}
	$scope.fn_44ab990a_d9f3_30fc_9197_6bf43915b7aa = function(scope, event) {
		filter_clear(scope, event);
	}
	$scope.fn_ddb0abb1_a4d5_345c_900e_40f8338da5ca = function(scope, event) {
		onInitPBCRImportDrawer(scope, event);
	}
	$scope.fn_4fd39d5b_1316_359f_a8a5_55677d677ec1 = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_654c9a0d_a0bd_3ac1_a2ff_f3ffbb992113 = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_b5467720_5e1f_3fa7_bfb2_fb79edbd2061 = function(scope, event) {
		Filter_reqResignDate(scope, event);
	}
	$scope.fn_86a05962_f54a_3517_b03f_722795c77167 = function(scope, event) {
		initTrainingRequest(scope, event);
	}
	$scope.fn_a7c48f9f_2834_3f8d_96e6_3fc759cf0371 = function(scope, event) {
		onInit_reqResign(scope, event);
	}
	$scope.fn_15807c5a_170a_3823_ad8f_f2dcfddb4372 = function(scope, event) {
		OniInitExternalTrainingReq(scope, event);
	}
	$scope.fn_1afb0b01_e2f3_3e73_b846_6bad5119c4f9 = function(scope, event) {
		initPeerReviewAppraisal(scope, event);
	}
	$scope.fn_75207ff1_5279_3b39_8279_8303fc6595b4 = function(scope, event) {
		addToCancelRequistion(scope, event);
	}
	$scope.fn_351e5836_1ec5_3bd4_b094_9d0ed2049cf3 = function(scope, event) {
		changeCompetencyType(scope, event);
	}
	$scope.fn_2ea31222_f388_338e_8611_7ca9acede8b9 = function(scope, event) {
		onInitKPICounselPage(scope, event);
	}
	$scope.fn_4f2275c2_1eab_3c2e_82df_e7b02c09b664 = function(scope, event) {
		addToCancelReq(scope, event);
	}
	$scope.fn_2b3de9f8_2194_35c9_999d_da0da1e9e6ef = function(scope, event) {
		oninitCompetencySettingDetail(scope, event);
	}
	$scope.fn_1bf89316_ebc1_3a19_9c37_8357cdb80796 = function(scope, event) {
		onInitKLKPIDashboardScreen(scope, event);
	}
	$scope.fn_e4834424_44b0_33fb_a621_4ef5374c586e = function(scope, event) {
		onInitKPISelfReviewNew(scope, event);
	}
	$scope.fn_3d9712a5_0e37_305e_a542_4eef8e243267 = function(scope, event) {
		oninitTeamBenefit(scope, event);
	}
	$scope.fn_17f8a26a_3756_3eb3_ac60_d00fbfd37de1 = function(scope, event) {
		onInitRM002jobDetail(scope, event);
	}
	$scope.fn_fa6928d0_f6d3_3cef_9001_06d17a34f7a2 = function(scope, event) {
		onInitPRMonitorManagementScreen(scope, event);
	}
	$scope.fn_ded05a26_9465_3917_b4f9_d502590c8829 = function(scope, event) {
		oninitSwapShiftReq(scope, event);
	}
	$scope.fn_96a10cd3_994a_370d_8524_ed616733b4f3 = function(scope, event) {
		onInitRMJobOpeningInternet(scope, event);
	}
	$scope.fn_3eb3aafb_b12c_3351_ba8c_ae1828f74cc7 = function(scope, event) {
		linkBackToHeaderCompe(scope, event);
	}
	$scope.fn_3e6480dc_ee0b_31f1_b5da_95302563f489 = function(scope, event) {
		onInitRM004_11(scope, event);
	}
}]);